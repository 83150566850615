<template>
	<div class="forget">
		<div class="form_center" v-if="show==1">
			<div class="center_title">
				<!-- 重置密码 -->{{$t('other.CZMM')}}
			</div>
			<div class="center_msg">
				<!-- 请输入您注册的电子邮件地址 -->{{$t('other.QSRNZCDDDYJDZ')}}
				<!-- 一封包含密码更改页面 URL 的电子邮件将发送到您的电子邮件地址。 -->
			</div>
			<el-form ref="form" label-width="130px">
				<el-form-item :label="$t('EMAILPHONE')">
					<el-input v-model="account"></el-input>
				</el-form-item>
			</el-form>
			<el-form ref="form" label-width="130px" v-if="isEmail==0">
				<el-form-item :label="$t('QXZDQ')">
					<el-select :placeholder="$t('QXZ')" v-model="country_code" >
					    <el-option
					      v-for="(item,index) in codelist"
					      :key="index"
					      :label="item.label"
					      :value="item.value" >
					    </el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-button type="primary" @click="getCode"><!-- 发送密码重置验证码 -->{{$t('FSMMCZYZM')}}</el-button>
			<router-link to="/login">
				<div class="center_btn">
					<!-- 返回登录 -->{{$t('other.FHDL')}}
				</div>
			</router-link>
		</div>

		<!-- 发送成功 -->
		<div class="form_center" v-if="show==2">
			<div class="center_title">
				<!-- 发送成功 -->{{$t('other.FSCG')}}
			</div>
			<div class="center_msg">
				{{account}}
			</div>
			<div class="center_msg">
				<!-- 确认电子邮件验证码已发送到注册的电子邮件 --><!-- {{$t('other.QDDZYJ')}} -->
				<div  v-if="isEmail==1">
					<!-- 确认电子邮件验证码已发送到注册的电子邮件 -->{{$t('other.QDDZYJ4')}}
				</div>
				<div  v-else>
					<!-- 确认电子邮件验证码已发送到注册的电子邮件 -->{{$t('other.QDDZYJ2')}}
				</div>
			</div>
			<el-input v-model="validate_code" :placeholder="$t('SRFOURYZM')"></el-input>
			<el-button type="primary" @click="checkCode"><!-- 下一步 -->{{$t('QY.XYB')}}</el-button>
			<button class="center_btn" @click="show=1">
				<!-- 返回，重新发送 -->{{$t('QY.FHCXFS')}}
			</button>

		</div>
		<div class="form_center" v-if="show==3">
			<div class="center_title">
				<!-- 重置密码 -->{{$t('other.CZMM')}}
			</div>
			<div class="center_msg">
				<!-- 请输入更改后的密码 -->{{$t('other.QSRGGHDMM')}}。
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
				<el-form-item :label="$t('task.MM')" prop="password">
					<el-input v-model="ruleForm.password" show-password autocomplete="new-password" :placeholder="$t('PWDRULE')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('task.QDMM')" prop="repassword">
					<el-input v-model="ruleForm.repassword" show-password autocomplete="new-password" :placeholder="$t('other.QCZSRMMYJXQD')"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" @click="setpwd"><!-- 更新登录信息 -->{{$t('CONFIRM')}}</el-button>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				type:0,
				show:1,
				account:'',
				validate_code:'',
				sign:'',
				country_code:'+81',
				codelist:[],
				isEmail:0,
				ruleForm:{
					password:'',
					repassword:'',
				},
				rules: {
					password: [
						{required: true,trigger: 'blur',message:this.$t('QSR')},
						{min:6,max:16,trigger: 'blur',message: this.$t('PWDRULE')}
					],
					repassword: [
						{required: true,trigger: 'blur',message:this.$t('QSR')},
						{min:6,max:16,trigger: 'blur',message: this.$t('PWDRULE')}
					],
					
				}
			};
		},
		created(){
			this.type=this.$route.query.type||0;
			this.getQuhao();
		},
		watch: {
		    
			account(){
				this.isEmail=this.account.indexOf('@')>-1?1:0;
				//console.log(this.account);
			}
		},
		methods: {
			async getQuhao(){
				let res=await this.$request.get('/api/sysdict/getCountryCode');
				this.codelist=res.data.records;
			},
			setpwd() {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						let url='/api/custuser/updatePassword';
						if(this.type==1){
							url='/api/bususer/updatePassword';
						}
						let res=await this.$request.post(url,{account:this.account,sign:this.sign,password:this.ruleForm.password,repassword:this.ruleForm.repassword});
						
						this.$message({
							message:res.message,
							type:'success'
						});
						this.clearlogin();
					}					
				});
				
			},
			clearlogin(){
				this.$emit('tuichu');
				
				if(this.type==1){
					this.$router.push({path:'/login_QY'});
				}else{
					this.$router.push({path:'/login'});
				}
			},
			
			async checkCode(){
				let res=await this.$request.get('/api/custauth/checkValidateCode',{account:this.account,validate_code:this.validate_code,sign:this.sign});
				this.show = 3;
			},
			async getCode(){
				let res=await this.$request.get('/api/custauth/sendValidateCode',{account:this.account,identify_cate:1,country_code:this.country_code});
				this.sign=res.data.sign;
				this.$message({
					message:res.message,
					type:'success'
				});
				this.show = 2;
				
			},
			
		}
	};
</script>

<style lang="less" scoped>
	.forget {
		// padding: 30px;
		min-height: 100vh;
		min-height: calc(100vh - 186px);
		background-color: #F3F1EA;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 90px;
		box-sizing: border-box;

		// padding-bottom: 90px;
		.form_center {
			width: 780px;
			padding: 58px 30px;
			box-sizing: border-box;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.center_title {
				font-size: 34px;
				color: #567BB6;
				font-weight: bold;
				margin-bottom: 50px;
			}

			.center_msg {
				font-size: 15px;
				margin-bottom: 20px;
			}

			.el-form {
				width: 474px;
			}
		}

		.login_logo {
			width: 389px;
			height: 72px;
			margin-bottom: 30px;
		}

		.el-button {
			background-color: #567BB6;
			border: none;
			margin-top: 30px;
		}

		.center_btn {
			font-size: 15px;
			color: #B65664;
			margin-top: 20px;
			background-color: #fff;
			border: none;
		}
	}
</style>
